.Container {
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.response {
    margin-top: 10px;
}

.search-box {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    border: 2px solid #ccc;
}

.Command {
    margin-bottom: 20px;
    border: 2px solid #ccc;
    padding: 10px;
}

.Command h3 {
    font-size: 1.2em;
    margin-bottom: 5px;
}

.Command p {
    margin-bottom: 10px;
}

.Command-inputs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.Command-input {
    margin-right: 10px;
    margin-bottom: 5px;
}

.Command-input label {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.Command-error {
    color: red;
    font-size: 0.9em;
    margin-top: 10px;
}

.Command-output {
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 10px;
    font-size: 0.9em;
    white-space: pre-wrap;
    overflow-x: auto;
    max-height: 200px;
}