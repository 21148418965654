.landing {
    margin: auto;
    align-content: center;
}

.title {
    text-align: center;
}

.center {
    display: block;
    text-align: center;
}