

.sign-out-button {
    margin-left: auto;
}

.nav-bar-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    top: 0;
    background: white;
    z-index: 10000;
    height: auto;
    overflow-y: auto;
}

#column-left {
    display: flex;
    flex-direction: row;
}

#column-right {
    display: flex;
    flex-direction: row;
}

.nav-bar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: 15px;
    margin-right: 10px;
}

.nav-link {
    margin-right: 10px;
    text-decoration: underline;
    font-size: 12px;
}
