/* src/pages/UpcomingListings.css */

.listings-container {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f9f9f9;
}

.header {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.filter-form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 30px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
    /* New Styles for Centering and Alignment */
    justify-content: center; /* Centers form groups horizontally */
    align-items: center;    /* Centers form groups vertically within each row */
}

.form-group {
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
    
    /* Ensure consistent height for alignment */
    min-height: 100px;
    
    /* New Styles for Centering Labels and Inputs */
    
    text-align: center;
    
    /* Add this to create space between label and input */
    justify-content: space-between;
    align-items: center;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: 600;
    color: #555;
}

.input-field {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;

    /* New Styles to Ensure Inputs are Centered */
    width: 100%;
    max-width: 300px;
}

.submit-button {
    padding: 12px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* justify-content: center; */
    align-self: center;
    
    /* Added margin-top for spacing from inputs */
    margin-top: 24px;
    
    /* New Styles for Consistent Width */
    width: 150px;
    margin: 0 auto; /* Center the button horizontally */
}

.submit-button:hover {
    background-color: #45a049;
}

.dirty-indicator {
    color: #ff6b6b;
    font-style: italic;
    margin-top: 10px;
    align-self: center; /* Centers the indicator horizontally */
}

.listings-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    position: relative;
}

/* Create a wrapper for the table to handle scrolling */
.table-wrapper {
    border: 1px solid #ddd;
    border-radius: 4px;
    position: relative;
    padding-bottom: 20px;
}

.listings-table thead th {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    font-weight: 700;
    color: #333;
    z-index: 1;
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
}

.listings-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
}

/* Remove or comment out the existing alternating row style */
/* .listings-table tr:nth-child(even) {
    background-color: #fafafa;
} */

/* Add new styles for alternating listing groups */
.listings-table tr[data-listing-index="even"] {
    background-color: #ffffff;
}

.listings-table tr[data-listing-index="odd"] {
    background-color: #e8f0ff;
}

/* Adjust the hover state to be slightly darker than both backgrounds */
.listings-table tr:hover {
    background-color: #d4e3ff;
}

.listings-table tbody {
    background-color: #fff;
}

.no-listings, .loading, .error-message, .filter-notification {
    text-align: center;
    font-size: 1em;
    margin-top: 20px;
}

.error-message {
    color: #e74c3c;
}

.filter-notification {
    color: #f39c12;
    font-weight: 500;
}

.submit-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

/* Style for the sentinel element */
.table-wrapper tr[ref] {
    height: 20px;
    visibility: hidden;
    display: block;
    width: 100%;
}

/* Add these styles for the number input */
.form-group input[type="number"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    width: 100px;
    text-align: center;
}

.form-group input[type="number"]::-webkit-inner-spin-button,
.form-group input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
    height: 24px;
}

/* Add these styles to your existing CSS file */
.clickable-row {
    cursor: pointer;
    transition: background-color 0.2s;
}

.clickable-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.expanded-row {
    background-color: #f8f9fa;
}

.expanded-content {
    padding: 20px;
    border-top: 1px solid #dee2e6;
}

.shift-details {
    max-width: 800px;
    margin: 0 auto;
}

.tier-schedule {
    margin-top: 15px;
    padding: 10px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tier-info {
    margin: 10px 0;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.tier-info:last-child {
    border-bottom: none;
}

.expanded-row td {
    padding: 0 !important;
}